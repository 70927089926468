<template>
  <div class="container-fluid container-app pt-3">
    <div class="row mb-3 align-items-end">
      <div class="col-12 col-md">
        <b-tabs
          content-class="mt-3"
          vertical
          pills
          nav-wrapper-class="w-25 text-uppercase font-weight-bold"
        >
          <b-tab title="Calendario" active> </b-tab>
          <b-tab title="Especialistas" active>
            <div class="row">
              <div class="col-12 col">
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    v-for="es in especialists"
                    :key="es._id"
                  >
                    <div class="row">
                      <div class="col">
                        {{ es.name }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="row align-items-end">
                      <div class="col">
                        <small>SELECCIONAR ESPECIALISTA</small>
                        <v-select
                          :options="users"
                          label="name"
                          v-model="selectedEspecialist"
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          variant="primary"
                          @click="addEspecialist"
                          block
                        >
                          Agregar
                        </b-button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab title="Servicios"> </b-tab>
          <b-tab title="This Month"> </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      especialists: [],
      users: []
    };
  },
  computed: {

    // selectedEspecialist: {
    //   get() {
    //     return this.$store.getters["users/getSelectedEspecialist"];
    //   },
    //   set(value) {
    //     this.$store.commit("users/setSelectedEspecialist", value);
    //   },
    // },
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$http
        .get("user")
        .then((res) => {
          this.users = res.data.data;
        })
        .catch(() => {
          this.$noty.error("No se pueden cargar los usuarios.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>